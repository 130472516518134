import React, { useState, useEffect, useMemo } from "react";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import { PlusCircle, RefreshCw } from "react-feather";
import { GlobalFilter } from "../GlobalFilter";
import { useNavigate } from "react-router-dom";
import Global from "../../utils/Global";
import Subject from "../../utils/Subject";
import RefreshButton from "../RefreshButton";
import patient_account_types from "../../config/patient_account_types";
import account_status from "../../config/account_status";
import ReactSwitch from "react-switch";

export function ReviewReportsTable({ subjectsLoading, setSubjectsLoading, reports, ...props }) {
  const navigate = useNavigate();
  const [filter, setFilter] = useState("");
  const [activeFilter, setActiveFilter] = useState(null);
  const [reportsState, setReportsState] = useState(reports);

  useEffect(() => {
    setReportsState(reports);
  }, [reports]);

  const toggleFilter = (newFilter) => {
    const isCurrentlyActive = activeFilter === newFilter;
    setFilter(isCurrentlyActive ? "" : newFilter); // Clear or apply new filter
    setActiveFilter(isCurrentlyActive ? null : newFilter); // Set active filter for UI feedback
  };

  const handleToggle = (reportId) => {
    setReportsState((prevReports) =>
      prevReports.map((report) =>
        report.subject_id === reportId ? { ...report, report_reviewed: !report.report_reviewed } : report
      )
    );
    // Optionally, you can also update the backend here
  };

  const initialState = {
    sortBy: [
      {
        id: "subject_id", // the accessor of the column you want to sort by
        desc: false, // false for ascending, true for descending
      },
    ],
  };

  const patientsData = useMemo(() => {
    if (!filter) return reportsState; // No filter applied
    return reportsState.filter((report) => {
      if (filter === "Show Reviewed Reports") {
        return report.report_reviewed;
      } else if (filter === "Hide Reviewed Reports") {
        return !report.report_reviewed;
      } else {
        return false;
      }
    });
  }, [reportsState, filter]);

  const patientsColumns = useMemo(
    () =>
      reportsState && reportsState[0]
        ? [
            {
              Header: "Subject ID",
              accessor: "subject_id",
            },
            {
              Header: "Remote Monitoring Period",
              accessor: (row) => (row.start_date + " - " + row.end_date),
              id: "remote_monitoring_period",
            },
            {
              Header: "View Report",
              accessor: "download_link",
              Cell: ({ value }) => <a href={value} target="_blank" rel="noopener noreferrer">download</a>, // Render link
            },
            {
              Header: "Report Reviewed",
              accessor: "report_reviewed",
              Cell: ({ row }) => (
                <ReactSwitch
                  checked={row.original.report_reviewed}
                  onChange={() => handleToggle(row.original.subject_id)}
                  onColor="#2196F3"
                  offColor="#ccc"
                />
              ),
            },
          ]
        : [],
    [reportsState]
  );

  const tableInstance = useTable(
    {
      columns: patientsColumns,
      data: patientsData,
      initialState, // include initialState here
    },
    useGlobalFilter,
    useSortBy
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, preGlobalFilteredRows, setGlobalFilter, state } = tableInstance;

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px" }}>
        {/* Plus button */}

        <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} setGlobalFilter={setGlobalFilter} globalFilter={state.globalFilter} />

        <div style={{ display: "flex", alignItems: "center" }}>
          {["Show Reviewed Reports", "Hide Reviewed Reports"].map((status, index, array) => (
            <>
              <button
                key={status}
                style={{
                  background: "none",
                  color: activeFilter === status ? "blue" : "gray",
                  cursor: "pointer",
                  border: "none",
                  textDecoration: activeFilter === status ? "underline" : "",
                  padding: "0 10px",
                }}
                onClick={() => toggleFilter(status)}
              >
                {status}
              </button>
              {index < array.length - 1 && <span style={{ margin: "0 5px" }}>/</span>}
            </>
          ))}
        </div>

      </div>
      <table className="table table-bordered" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <img src={require("../../images/down_arrow.png")} alt="" />
                    ) : (
                      <img src={require("../../images/up_arrow.png")} alt="" />
                    )
                  ) : column.Header === "Actions" ? (
                    ""
                  ) : (
                    <img src={require("../../images/default.png")} alt="" />
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {subjectsLoading ? (
            <tr>
              <td colSpan={patientsColumns.length}>Loading...</td>
            </tr>
          ) : rows.length > 0 ? (
            rows.map((row, rowIdx) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIdx) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={patientsColumns.length}>No Reports Available.</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
