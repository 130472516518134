import status from "../config/status";
import Treatment from "./Treatment";
import PrimaryCondition from "./PrimaryCondition";
import FormError from "./Errors/FormError";
import Form from "./Form/Form";
import PatchWrapperSingle from "./Form/PatchWrapperSingle";
import PatchWrapperArray from "./Form/PatchWrapperArray";
import FieldWrapperSingle from "./Form/FieldWrapperSingle";
import FieldWrapperArray from "./Form/FieldWrapperArray";
import APIService from "./Services/APIService";
import Trial from "./Trial";
import { onCheckWeeks, onCheckAge, onCheckHeight, onCheckWeight, onCheckPrimaryCondition } from "./validators/checkInputs";
import { dateValidator } from "./validators/dateValidator";
import PatchForm from "./Form/PatchForm";
import treatment_status_options from "../config/treatment_status_options";
import { cloneDeep } from "lodash";
import account_status_options from "../config/account_status";
import patient_account_type_options from "../config/patient_account_types";
import Period from "./Period";
import moment from "moment";
import display_types from "../config/display_types";
export default class Subject {
  constructor({ subject_id = null, account_status, account_type, name, first_name, last_name, age, dob, sex, height, weight, primary_conditions, treatments, treatment_status, phone_number = null, most_recent_collection_date = null, days_pending = null, trials = null }) {
    this._subject_id = subject_id;
    this._account_status = account_status;
    this._account_type = account_type
    this._name = name;
    this._first_name = first_name;
    this._last_name = last_name;
    this._age = age;
    this._dob = dob;
    this._sex = sex;
    this._height = height;
    this._weight = weight;
    this._primary_conditions = primary_conditions;
    this._treatments = treatments;
    this._treatment_status = treatment_status;
    this._phone_number = phone_number;
    this._most_recent_collection_date = most_recent_collection_date;
    this._days_pending = days_pending;
    this._trials = trials;
  }

  static createFromForm(subject_form, display_type) {
    let exceptionOccurred = false;

    if (Subject.validateSubjectForm(subject_form, display_type)) {
      exceptionOccurred = true;
    }    
    const subject = new Subject({}); // Ensure that Subject is defined/imported properly
    const fields = Object.values(subject_form);

    fields.forEach((field) => {
      try {
        // Assuming 'attribute_name' and 'getUI' are defined in your field's structure
        subject[field.attribute_name] = field.getUI();
      } catch (error) {
        field.error = error.message;
        exceptionOccurred = true;
      }
    });

    if (exceptionOccurred) {
      // Ensure that FormError is defined/imported properly
      throw new FormError("SubjectForm Error Occurred!", subject_form);
    } else {
      return subject;
    }
  }

  static validateSubjectForm(subjectForm, display_type) {
    let exceptionOccurred = false;
    if (display_type === display_types.PHI) {
      if (!subjectForm.first_name.getUI()) {
        subjectForm.first_name.error = "First Name cannot be empty."
        exceptionOccurred = true;
      }
      if (!subjectForm.last_name.getUI()) {
        subjectForm.last_name.error = "Last Name cannot be empty."
        exceptionOccurred = true;
      }

      let formattedDate = subjectForm.dob.getUI()
      if (formattedDate && formattedDate instanceof Date) {
        formattedDate = formattedDate.toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }).replace(/\//g, '-');
      }
      const dateError = dateValidator(formattedDate)
      if (dateError) {
        subjectForm.dob.error = dateError;
        exceptionOccurred = true;
      }
    } else if (display_type === display_types.NO_PHI) {
      if (subjectForm.age.getUI() < 2 || subjectForm.age.getUI() > 100) {
        subjectForm.age.error = "Enter age (2-100).";
      }
    }
    return exceptionOccurred;
  }

  static convertDate_MMDDYYYY(date) {
    if (!date) {
      return date;
    }
    const parts = date.split('-');
    return `${parts[1]}-${parts[2]}-${parts[0]}`;  // Reformat to MM-DD-YYYY
  }

  static convertDate_YYYYMMDD(date) {
    if (!date) {
      return date;
    }
    const parts = date.split('-');
    return `${parts[2]}-${parts[0]}-${parts[1]}`; // Reformat to YYYY-MM-DD
  }

  static createFromAPI(subject_api) {
    return new Subject({
      subject_id: subject_api.subject_id,
      account_status: subject_api.account_status,
      account_type: subject_api.account_type,
      name: subject_api.name,
      first_name: subject_api.first_name,
      last_name: subject_api.last_name,
      age: subject_api.age,
      dob: Subject.convertDate_MMDDYYYY(subject_api.dob),
      sex: subject_api.sex,
      height: subject_api.height,
      weight: subject_api.weight,
      primary_conditions: subject_api.primary_conditions
        ? subject_api.primary_conditions.map((primary_condition) => PrimaryCondition.createFromAPI(primary_condition))
        : null,
      treatments: subject_api.treatments ? subject_api.treatments.map((treatment) => Treatment.createFromAPI(treatment)) : null,
      treatment_status: subject_api.treatment_status,
      phone_number: subject_api.phone_number, // TODO: this may never be passed from API not sure. may be passed in a different format
      most_recent_collection_date: subject_api.most_recent_collection_date ? (moment.utc(subject_api.most_recent_collection_date).local()).format('YYYY-MM-DD HH:mm:ss') : subject_api.most_recent_collection_date,
      days_pending: subject_api.days_pending
    });
  }

  static createForm(subject = null) {
    return new Form({
      properties: {
        account_status: new FieldWrapperSingle({ attribute_name: "account_status", value: subject ? subject.account_status : null, error: "", value_validator: null }),
        account_type: new FieldWrapperSingle({ attribute_name: "account_type", value: subject ? subject.account_type : patient_account_type_options.remote, error: "", value_validator: null }),
        name: new FieldWrapperSingle({ attribute_name: "name", value: subject ? subject.name : "", error: "", value_validator: null }),
        first_name: new FieldWrapperSingle({ attribute_name: "first_name", value: subject && subject.first_name ? subject.first_name : "", error: "", value_validator: null}),
        last_name: new FieldWrapperSingle({ attribute_name: "last_name", value: subject && subject.last_name ? subject.last_name : "", error: "", value_validator: null}),
        age: new FieldWrapperSingle({ attribute_name: "age", value: subject ? subject.age : "", error: "", value_validator: null }),
        dob: new FieldWrapperSingle({ attribute_name: "dob", value: subject && subject.dob ? subject.dob : "", error: "", value_validator: null}),
        sex: new FieldWrapperSingle({ attribute_name: "sex", value: subject ? subject.sex : null, error: "", value_validator: null }),
        height: new FieldWrapperSingle({
          attribute_name: "height",
          value: subject ? subject.height : "",
          error: "",
          value_validator: null,
        }),
        weight: new FieldWrapperSingle({
          attribute_name: "weight",
          value: subject ? subject.weight : "",
          error: "",
          value_validator: null,
        }),
        primary_conditions: new FieldWrapperArray({
          attribute_name: "primary_conditions",
          value: subject ? subject.primary_conditions : null,
          error: "",
          value_validator: null,
        }),
        treatments: new FieldWrapperArray({ attribute_name: "treatments", value: subject ? subject.treatments : null, error: "", value_validator: null }),
        treatment_status: new FieldWrapperSingle({
          attribute_name: "treatment_status",
          value: subject ? subject.treatment_status : treatment_status_options.pre_op,
          error: "",
          value_validator: null,
        }),
        phone_number: new FieldWrapperSingle({
          attribute_name: "phone_number",
          value: subject ? subject.phone_number : null,
          error: "",
          value_validator: null,
        }),
      },
    });
  }

  static createPatchForm(subject = null) {
    return new PatchForm({
      properties: {
        account_status: new PatchWrapperSingle({ attribute_name: "account_status", value: subject ? subject.account_status : null, error: "", value_validator: null }),
        account_type: new PatchWrapperSingle({ attribute_name: "account_type", value: subject ? subject.account_type : patient_account_type_options.remote, error: "", value_validator: null }),
        name: new PatchWrapperSingle({ attribute_name: "name", value: subject ? subject.name : "", error: "", value_validator: null }),
        first_name: new PatchWrapperSingle({ attribute_name: "first_name", value: subject && subject.first_name ? subject.first_name : "", error: "", value_validator: null}),
        last_name: new PatchWrapperSingle({ attribute_name: "last_name", value: subject && subject.last_name ? subject.last_name : "", error: "", value_validator: null}),
        age: new PatchWrapperSingle({ attribute_name: "age", value: subject ? subject.age : "", error: "", value_validator: null }),
        dob: new PatchWrapperSingle({ attribute_name: "dob", value: subject && subject.dob ? subject.dob : "", error: "", value_validator: null}),
        sex: new PatchWrapperSingle({ attribute_name: "sex", value: subject ? subject.sex : null, error: "", value_validator: null }),
        height: new PatchWrapperSingle({
          attribute_name: "height",
          value: subject ? subject.height : "",
          error: "",
          value_validator: null,
        }),
        weight: new PatchWrapperSingle({
          attribute_name: "weight",
          value: subject ? subject.weight : "",
          error: "",
          value_validator: null,
        }),
        primary_conditions: new PatchWrapperArray({
          attribute_name: "primary_conditions",
          value: subject ? subject.primary_conditions : null,
          error: "",
          value_validator: null,
        }),
        treatments: new PatchWrapperArray({ attribute_name: "treatments", value: subject ? subject.treatments : null, error: "", value_validator: null }),
        treatment_status: new PatchWrapperSingle({
          attribute_name: "treatment_status",
          value: subject ? subject.treatment_status : treatment_status_options.pre_op,
          error: "",
          value_validator: null,
        }),
        phone_number: new PatchWrapperSingle({
          attribute_name: "phone_number",
          value: subject ? subject.phone_number : null,
          error: "",
          value_validator: null,
        }),
      },
    });
  }

  static sendToAPI(subject) {
    const cleanedSubject = {};
    cleanedSubject['subject_id'] = subject.subject_id;
    cleanedSubject['account_status'] = subject.account_status;
    cleanedSubject['account_type'] = subject.account_type;
    cleanedSubject['name'] = subject.name;
    if (subject.first_name) {
      cleanedSubject['first_name'] = subject.first_name
    }
    if (subject.last_name) {
      cleanedSubject['last_name'] = subject.last_name
    }
    if (subject.dob) {
      cleanedSubject['dob'] = Subject.convertDate_YYYYMMDD(subject.dob);
    } else {
      cleanedSubject['age'] = subject.age;
    }
    cleanedSubject['sex'] = subject.sex;
    cleanedSubject['height'] = subject.height;
    cleanedSubject['weight'] = subject.weight;
    cleanedSubject['primary_conditions'] = subject.primary_conditions;
    cleanedSubject['treatments'] = subject.treatments;
    cleanedSubject['treatment_status'] = subject.treatment_status;

    let phoneNumber = subject.phone_number;
      if (phoneNumber) {
      // Remove all characters that aren't numbers or the + symbol
      phoneNumber = phoneNumber.replace(/[^\d+]/g, '');
      // Check if the phone number starts with +1
      if (!phoneNumber.startsWith('+1')) {
        phoneNumber = '+1' + phoneNumber.replace(/^\+/, ''); // Ensure only one + at the start
      }
    }
    cleanedSubject['phone_number'] = phoneNumber;
    
    cleanedSubject.primary_conditions = subject.primary_conditions ? subject.primary_conditions.map(condition => {
      const cleanedCondition = {};
  
      Object.keys(condition).forEach(key => {
        if (key !== "_description") {
          const newKey = key.startsWith('_') ? key.substring(1) : key;  // Remove the leading '_'
          if (key === "_approximate_date") {

            if (condition[key] === "") {
              cleanedCondition[newKey] = null
            } else {
              // Reformat from MM-DD-YYYY to YYYY-MM-DD
              cleanedCondition[newKey] =  PrimaryCondition.sendDate(condition[key]);
            }
            // cleanedCondition[newKey] = condition[key] === "" ? null : condition[key]
          } else {
            cleanedCondition[newKey] = condition[key];
          }
        }
      });
  
      return cleanedCondition;
  }) : null;

  cleanedSubject.treatments = subject.treatments ? subject.treatments.map(treatment => {
    const cleanedTreatment = {};

    Object.keys(treatment).forEach(key => {
      if (key !== "_description") {
        const newKey = key.startsWith('_') ? key.substring(1) : key;  // Remove the leading '_'
        cleanedTreatment[newKey] = treatment[key];
      }
    });

    return cleanedTreatment;
}) : null;

    // console.log(cleanedSubject);
    return cleanedSubject;
  }

  static getSubjectList({include_conditions, include_treatments, include_code_desc, include_days_pending, account_status, account_type, most_recent_collection_date}) {
    let path_base = "/subject/";
    let query_params = [];
    if (include_conditions) {
      query_params.push("include_conditions=true");
    }
    if (include_treatments) {
      query_params.push("include_treatments=true");
    }
    if (include_code_desc) {
      query_params.push("include_code_desc=true");
    }
    if (include_days_pending) {
      query_params.push("include_days_pending=true");
    }
    if (account_status) {
      query_params.push("account_status=" + account_status);
    }
    if (account_type) {
      query_params.push("account_type=" + account_type)
    }
    if (most_recent_collection_date) {
      query_params.push("most_recent_collection_date=" + most_recent_collection_date)
    }

    return new Promise((resolve, reject) => {
      APIService.get(path_base, query_params)
        .then((subjectList) => {
          resolve(subjectList["subjects"].map((subject) => Subject.createFromAPI(subject))); // for each subject in subject list call createFromAPI, stick them all in a list
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static getSubjectName(auto_generate_name = true) {
    let path_base = "/subject/";
    let query_params = [];
    if (auto_generate_name) {
      query_params.push("auto_generate_name=true");
    }

    return new Promise((resolve, reject) => {
      APIService.get(path_base, query_params)
        .then((subject_name) => {
          resolve(subject_name.name);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static getSubject(subject_id, include_conditions = false, include_treatments = false, include_code_desc = false, include_days_pending = false, include_phone_number = false) {
    let path_base = "/subject/" + subject_id;
    let query_params = [];
    if (include_conditions) {
      query_params.push("include_conditions=true");
    }
    if (include_treatments) {
      query_params.push("include_treatments=true");
    }
    if (include_code_desc) {
      query_params.push("include_code_desc=true");
    }
    if (include_days_pending) {
      query_params.push("include_days_pending=true");
    }
    if (include_phone_number) {
      query_params.push("include_phone_number=true");
    }

    return new Promise((resolve, reject) => {
      APIService.get(path_base, query_params)
        .then((subject) => {
          resolve(Subject.createFromAPI(subject)); // for each subject in subject list call createFromAPI, stick them all in a list
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

static getPeriod({
    subject_id,
    include_current_period,
    include_all_periods,
    include_trials
  }) {
    let path_base = '/subject/' + subject_id + '/periods';
    let query_params = [];
    if (include_current_period) {
      query_params.push('include_current_period=true');
    }
    if (include_all_periods) {
      query_params.push('include_all_periods=true');
    }
    if (include_trials) {
      query_params.push('include_trials=true');
    }

    return new Promise((resolve, reject) => {
      APIService.get(path_base, query_params)
        .then((response) => {
          if (response.periods && Array.isArray(response.periods)) {
            resolve(response.periods.map(period => Period.createFromAPI(period)))
          } else if (response) {
            resolve(Period.createFromAPI(response.current_period));
          } else {
            reject(status.error);
          }
      });
    });
  }

  static getTrialList(subject_id, include_metrics = false, start_date = null, end_date = null, date = null, trial_name = null) {
    let path_base = "/subject/" + subject_id + "/trials/";
    let query_params = [];
    if (include_metrics) {
      query_params.push("include_metrics=true");
    }
    if (start_date) {
      query_params.push(`start_date=${start_date}`);
    }
    if (end_date) {
      query_params.push(`end_date=${end_date}`);
    }
    if (date) {
      query_params.push(`date=${date}`);
    }
    if (trial_name) {
      query_params.push(`trial_name=${trial_name}`);
    }
    return new Promise((resolve, reject) => {
      APIService.get(path_base, query_params)
        .then((trials) => {
          resolve(
            trials["trials"]
            .map((trial) => Trial.createFromAPI(trial))
            .sort((a, b) => new Date(a.trial_date) - new Date(b.trial_date))
          );
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static getTrial(subject_id, trial_id, include_metrics = false) {
    let path_base = "/subject/" + subject_id + "/trials/" + trial_id;
    let query_params = [];
    if (include_metrics) {
      query_params.push("include_metrics=true");
    }
    return new Promise((resolve, reject) => {
      APIService.get(path_base, query_params)
        .then((trial) => {
          // console.log("TRIAL FROM API")
          // console.log(trial);
          resolve(Trial.createFromAPI(trial));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static postSubject(subject) {
    let path_base = "/subject/";
    let body = Subject.sendToAPI(subject);
    return new Promise((resolve, reject) => {
      APIService.post(path_base, body).then((subject) => {
        resolve(Subject.createFromAPI(subject))
      }).catch((err) => {
        reject(err);
      })
    });
  }

  static preparePatchSet(subjectForm) {
    for (const [key, fieldWrapper] of Object.entries(subjectForm)) {
      if (fieldWrapper instanceof PatchWrapperArray) {
        if (fieldWrapper.attribute_name === "treatments" || fieldWrapper.attribute_name === "primary_conditions") {
          for (const patch of fieldWrapper.value) {
            // Check if description field exists in the patch and delete it
            if (patch.value.description !== undefined) {
              delete patch.value._description;
            }
            if (fieldWrapper.attribute_name === "primary_conditions") {
              if (!patch.value.approximate_date) {
                patch.value.approximate_date = null;
              } else {
                patch.value.approximate_date = PrimaryCondition.sendDate(patch.value.approximate_date)
              }
            }
            // Remove underscores from keys in patch.value
            const cleanedPatchValue = {};
            Object.keys(patch.value).forEach((key) => {
              const cleanedKey = key.startsWith("_") ? key.substring(1) : key; // Remove leading underscore
              cleanedPatchValue[cleanedKey] = patch.value[key];
            });

            // Assign the cleanedPatchValue back to patch.value
            patch.value = cleanedPatchValue;
          }
        }
      } else if (fieldWrapper instanceof PatchWrapperSingle) {
        if (fieldWrapper.attribute_name === "phone_number") {

          let phoneNumber = fieldWrapper.value.value;
          if (phoneNumber) {
          // Remove all characters that aren't numbers or the + symbol
          phoneNumber = phoneNumber.replace(/[^\d+]/g, '');
          // Check if the phone number starts with +1
          if (!phoneNumber.startsWith('+1')) {
            phoneNumber = '+1' + phoneNumber.replace(/^\+/, ''); // Ensure only one + at the start
          }
        }
          fieldWrapper.value.value = phoneNumber;
        } else if (fieldWrapper.attribute_name === "dob") {
          let dob = fieldWrapper.value.value;
          if (dob) {
            dob = Subject.convertDate_YYYYMMDD(dob)
          }
          fieldWrapper.value.value = dob;
        }

      }
    }
  }

  static patchSubject(subject_id, subjectForm) {
    let path_base = "/subject/" + subject_id;
    const duplicateSubjectForm = cloneDeep(subjectForm);
    
    Subject.preparePatchSet(duplicateSubjectForm);
    let body = duplicateSubjectForm.generatePatchSet();

    return new Promise((resolve, reject) => {
      if (body.length === 0) {
        resolve(null);
      } else {
        // resolve();
        APIService.patch(path_base, body).then((subject) => {
          resolve(Subject.createFromAPI(subject))
        }).catch((err) => {
          reject(err);
        })
      }


    });
  }

  static resendConfirmationCode(subject_id) {
    let path_base = "/subject/" + subject_id;
    let body = [{op: 'replace', path: '/resend_invite', value: 'true'}]

    return new Promise((resolve, reject) => {
      APIService.patch(path_base, body).then(() => {
        resolve(status.success)
      }).catch((err) => {
        reject(err);
      })
    });
  }

  get subject_id() {
    return this._subject_id;
  }
  set subject_id(subject_id) {
    this._subject_id = subject_id;
  }

  get account_status() {
    return this._account_status;
  }
  set account_status(account_status) {
    // can pass in null for account_status when creating a new patient, or can be an existing value if looking at already made subject.
    if (!account_status || Object.values(account_status_options).includes(account_status)) {
      this._account_status = account_status;
    } else {
      throw new Error("Invalid account status.")
    }
  }

  get account_type() {
    return this._account_type;
  }
  set account_type(account_type) {
    if (Object.values(patient_account_type_options).includes(account_type)) {
      this._account_type = account_type;
    } else {
      throw new Error("Invalid account type.")
    }
  }

  get name() {
    return this._name;
  }
  set name(name) {
    // Check if the name is empty
    if (!name) {
      throw new Error("Name cannot be empty.");
    }

    // Check if the name contains only letters and numbers and is at most 10 characters long
    const nameRegex = /^[a-zA-Z0-9]{1,10}$/;
    if (!nameRegex.test(name)) {
      throw new Error("Name must only contain letters and numbers and be at most 10 characters long.");
    }

    this._name = name;
  }

  get first_name() {
    return this._first_name;
  }
  set first_name(first_name) {
    this._first_name = first_name;
  }

  get last_name() {
    return this._last_name;
  }
  set last_name(last_name) {
    this._last_name = last_name;
  }

  get age() {
    return this._age;
  }
  set age(age) {
    this._age = age;
  }

  get dob() {
    return this._dob;
  }
  set dob(dob) {
    this._dob = dob;
  }

  get sex() {
    return this._sex;
  }
  set sex(sex) {
    if (["M", "F", "X"].includes(sex)) {
      this._sex = sex;
    } else {
      throw new Error("Select sex.");
    }
  }

  get height() {
    return this._height;
  }
  set height(height) {
    if (height >= 36 && height <= 96) {
      this._height = height;
    } else {
      throw new Error("Enter height (36-96).");
    }
  }

  get weight() {
    return this._weight;
  }
  set weight(weight) {
    if (weight >= 20 && weight <= 800) {
      this._weight = weight;
    } else {
      throw new Error("Enter weight (20-800).");
    }
  }

  get primary_conditions() {
    return this._primary_conditions;
  }
  set primary_conditions(primary_conditions) {
    if (!primary_conditions) {
      throw new Error("Enter primary condition.");
    } else if (primary_conditions.length === 0) {
      throw new Error("Enter primary condition.");
    } else if (primary_conditions.every((item) => item instanceof PrimaryCondition)) {
      this._primary_conditions = primary_conditions;
    } else {
      throw new Error("Enter primary condition.");
    }
  }

  get treatments() {
    return this._treatments;
  }
  set treatments(treatments) {
    if (!treatments) {
      this._treatments = null;
    } else if (treatments.every((item) => item instanceof Treatment)) {
      this._treatments = treatments;
    } else {
      throw new Error("Select treatment.");
    }
  }

  get treatment_status() {
    return this._treatment_status;
  }
  set treatment_status(treatment_status) {
    if (Object.values(treatment_status_options).includes(treatment_status)) {
      this._treatment_status = treatment_status;
    } else {
      throw new Error("Select treatment status");
    }
  }

  get phone_number() {
    return this._phone_number;
  }

  set phone_number(phone_number) {
    if (this.account_type === patient_account_type_options.inclinic) {
      this._phone_number = null;
      return;
    }
    // This regex checks for a US phone number format
    const phoneRegex = /^(?:\+1[-.\s]?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
    if (!phoneRegex.test(phone_number)) {
      throw new Error("Invalid US phone number format");
    }
    this._phone_number = phone_number;
  }

  get most_recent_collection_date() {
    return this._most_recent_collection_date;
  }
  set most_recent_collection_date(most_recent_collection_date) {
    this._most_recent_collection_date = most_recent_collection_date;
  }

  get days_pending() {
    return this._days_pending;
  }
  set days_pending(days_pending) {
    this._days_pending = days_pending;
  }

  get trials() {
    return this._trials;
  }
  set trials(trials) {
    this._trials = trials;
  }
}
