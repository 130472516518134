import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import Global from "../../utils/Global";

const DropdownTable = ({ title, data, startOpen = false, reportReady = true, filename }) => {
  const [open, setOpen] = useState(startOpen);
  const navigate = useNavigate();

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleRowClick = (trial_id) => {
    const newPath = window.location.pathname.slice(0, window.location.pathname.lastIndexOf("/")) + "/trials/" + trial_id    

    Global.localAppHandler.app_set_active_trial(trial_id).then((trial_id) => {
      navigate(newPath);
    }).catch((returnStatus) => {
      console.log("this should never happen");
    })
  };

  const handleDownload = (e) => {
    e.stopPropagation();
    const pdfPath = `${process.env.PUBLIC_URL}/${filename}`; // Path to the PDF in the public folder
    saveAs(pdfPath, filename);
  };

  return (
    <div className="mb-4 border rounded bg-white px-3">
      <div className="d-flex justify-content-between align-items-center p-3 bg-white" onClick={handleToggle} style={{ cursor: 'pointer' }}>
        <span className="font-weight-bold h5">{title}</span>
        <div className="d-flex align-items-center">
          {reportReady ? <>
            <a href="#" className="mr-3 btn btn-link p-0" onClick={handleDownload}>Download Report</a>
          </> : <div className="mr-3 btn p-0"> Report Not Ready</div>}
          {open ? <ChevronUp /> : <ChevronDown />}
        </div>
      </div>
      {open && (
        <table className="table table-bordered bg-white">
          <thead className="thead-light">
            <tr>
              <th>Trial Name</th>
              <th>Trial Date</th>
            </tr>
          </thead>
          <tbody>
            { data.length > 0 ? <>
              {data.map((row, index) => (
              <tr key={index} onClick={() => handleRowClick(row.trial_id)} style={{ cursor: 'pointer' }}>
                <td className="text-primary">{row.trial_name}</td>
                <td className="text-primary">{row.trial_date}</td>
              </tr>
            ))}
            </> : <>
              <tr>
                <td>{"No Trials Recorded"}</td>
              </tr>
            </>}

          </tbody>
        </table>
      )}
    </div>
  );
};

export default DropdownTable;

